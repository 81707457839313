import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './components/home/Home'
import Quotchy from './components/quotchy/Quotchy'
import chem111tb from './documents/chem111tb.pdf';
import ptable from './documents/ptable.pdf';
import Valentine from './components/vday/Valentine';

function App() {
    const [title, setTitle] = useState('arjayyyy');
    const onFocus = () => {
        console.log("back");
        setTitle('arjayyyy');
    }

    const onBlur = () => {
        console.log("left");
        setTitle('Come back \'ere!');
    }

    useEffect(() => {

        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);

    return (
            <div>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Home/>
                        </Route>
                        <Route exact
                               path="/linkedin"
                               render={() =>
                                   (window.location = "https://www.linkedin.com/in/arjayandal/")}
                        />
                        <Route exact
                               path="/github"
                               render={() =>
                                   (window.location = "https://github.com/rjworks/")}
                        />
                        <Route exact
                               path="/case-closed"
                               render={() =>
                                   (window.location = "https://case-closed.netlify.app/")}
                        />
                        <Route exact
                               path="/camchify"
                               render={() =>
                                   (window.location = "https://camchify.netlify.app/")}
                        />
                        <Route path="/quotchy">
                            <Quotchy/>
                        </Route>

                        <Route path="/vday">
                           <Valentine/>
                       </Route>
                        <Route exact
                               path='/chem111tb'
                               render={() => window.open(chem111tb, "_self")}/>
                        <Route exact path='/ptable' render={() => window.open(ptable, '_self')}/>
                    </Switch>
                </Router>
            </div>
    );
}

export default App;
